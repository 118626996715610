import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
 import { useSelector } from 'react-redux'
// import { user_details } from '../../store/user-slice'


const Navbar = (props) => {
  const history = useHistory();
  const [data ,setData] = useState ([])
  // const dispatch = useDispatch()
  const user =useSelector((state) => state.details)
 
  
   
 
  // useEffect(()=>{
   
  //     getVesselsDetails() 
     
      
  // },[])
  // const getVesselsDetails = async ()=>{
    
  //     const response = await API.get(VESSEL_WITH_ID_URL(props.user.vesselId));
  //     if (response.status === 200) {
        
  //       setData(response.data)
  //     }
  
  // }
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const getlogout = () => {
    localStorage.removeItem('access_token');
    history.push('/login')
  }
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="icon-menu"></span>
        </button>
        <Link className="navbar-brand brand-logo" to="/"><img
          // @ts-ignore
          src={require("../../assets/images/logo.png")} className="me-2" alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to="/"><img
          // @ts-ignore
          src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
          
            <h1 className="welcome-text">Hello {user.user.roles.includes('admin') ?'Admin' :user.user.roles.includes('all')? 'Super Admin': ''},  <span className="text-black fw-bold">{user.user.roles.includes('all')?'':user.user.roles.includes('admin')?user.user.email:user.user.firstname +' '+ user.user.lastname}  </span></h1>
          
            {/* <h3 className="welcome-sub-text">Here is your live MIDAS Dashboard </h3> */}
          </li>
          
        </ul>
        {/* <div style={{paddingLeft:'20%'}}> </div> */}
        
       
        <ul className="navbar-nav navbar-nav-right">
        <li>
        <img
          // @ts-ignore
          src={require("../../assets/images/cwit_logo.png")} className="me-2" alt="logo" style={{height:40}}/>
          </li>
 
          <li className="nav-item">
            <form className="search-form" action="#">
              <i className="icon-search"></i>
              <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
            </form>
          </li>


          <li className="nav-item nav-profile">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator">
                <img
                  // @ts-ignore
                  src={require("../../assets/images/faces/face28.jpg")} alt="profile" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown" >
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="d-flex align-items-center">
                    <i className="ti-settings text-primary"></i>
                    <span className="ps-2">Settings</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item preview-item" onClick={getlogout}>
                  <div className="d-flex align-items-center">
                    <i className="ti-power-off text-primary"></i>
                    <span className="ps-2">Logout</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  );

}

export default Navbar;
